import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';
import useFetchItems from '../../hooks/useFetchItems';
import AppServices from '../../services/general/appServices';
import Widgets from '../simple/Widgets';
import Widgets2 from '../simple/Widgets2';
import ToTop from '../simple/ToTop';

const Footer = ({item, footerEnabled}) => {
    const [logo, setLogo] = useState();
    const [text, setText] = useState();
    const [widgets, setWidgets] = useState();
    const [widgetsOpen, setWidgetsOpen] = useState();

    useEffect(() => {
        if(item){
            setLogo(item.find(it => it.type === "white-logo")?.value)
            setText(item.find(it => it.type === "footer_text"))
            setWidgets(item.find(it => it.type === "widgets")?.value)
            setWidgetsOpen(item.find(it => it.type === "widgets-open")?.value)
        }
    }, [item])

    const { 
        items,
    } = useFetchItems(AppServices.menu, "bottom-menu");

  return (
    <>
    {footerEnabled && <footer className="section footer footer-s1">
      <div className="container">
        <div className="row gutter-vr-30px">
          <div className="col-lg-3 col-sm-6">
            <div className="wgs">
              <div className="wgs-content">
                <div className="wgs-logo">
                  <NavLink to="/">
                    <img src={logo} alt="logo"/>
                  </NavLink>
                </div>
                {text?.value.map((em, index) => 
                  <p key={index}>{em}</p>
                )}
              </div>
            </div>
          </div>
          {items?.map((elem, index) =>
            <div className="col-lg-3 col-sm-6" key={index}>
              <div className="wgs">
                <div className="wgs-content">
                  <h3 className="wgs-title">{elem.title}</h3>
                  <ul className="wgs-menu">
                    {elem.items?.map((el, index) =>
                        <li key={index}>
                            {el.value?.startsWith('#') ?
                                <a href={el.value}>{el.title}</a>
                                :
                                <NavLink to={el.value} target={el.type}>{el.title}</NavLink>
                            }
                            </li>
                    )}
                  </ul>
                </div>
              </div>
            </div> 
            )}
        </div>
      </div>
    </footer>}
    <ToTop widgets={widgets}/>  
    {widgets && 
        <>
            {widgetsOpen ?
                <Widgets item={widgets} setWidgetsOpen={setWidgetsOpen} widgetsOpen={widgetsOpen}/>
            :
                <Widgets2 item={widgets}/> 
            }
        </>
    }
    </>
  )
}

export default Footer